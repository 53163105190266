<template>
    <div class="modal-row2 modal-height-max">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 0 }" @click="activeTab = 0" id="allotment-tab"
                       data-toggle="tab" href="#allotment" role="tab" aria-controls="allotment"
                       aria-disabled="true">{{ t('ALLOTMENT.GENERAL') }}</a>
                </li>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 1 }" @click.stop.prevent="validateSecondAbaClick"
                       id="localization-tab" data-toggle="tab" href="#localization" role="tab"
                       aria-controls="localization"
                       aria-disabled="true">{{ t('ALLOTMENT.LOCATION') }}</a>
                </li>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 2 }" @click.stop.prevent="validateLastAbaClick"
                       id="financial-tab" data-toggle="tab" href="#financial" role="tab" aria-controls="financial"
                       aria-disabled="true">{{ t('ALLOTMENT.FINANCIAL') }}</a>
                </li>
            </div>
        </ul>

        <form @submit.prevent="create">
            <div class="tab-content modal-body" id="myTabContent">
                <div class="tab-pane fade" :class="{ active: activeTab == 0, show: activeTab == 0 }" id="allotment"
                     role="tabpanel" aria-labelledby="allotment-tab">

                    <div class="row gutters">
                        <div class="dimensions mb-2" style="margin-left: 34%;">altura e largura: Max: 380 x 380px - Min:
                            150 x
                            150px
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="logo-size logo mb-2"
                                 :style="widthPhone() ? 'margin-left: 35% !important' : 'margin-left: 42% !important'">
                                <img class="user-logo" :src="imageCropped ? imageCropped : allotment.image"/>
                                <div class="status pointer">
                                    <label for="logo"><i class="icon-camera camera2"/></label>
                                    <input type="file" id="logo" data-cy="allotment_creat_add_image"
                                           accept="image/jpeg/*"
                                           @change="uploadImage()" style="display: none"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="active"
                                       v-model="allotment.active">
                                <label class="custom-control-label" for="active">{{ t('ALLOTMENT.ACTIVE') }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label for="name" class="required">{{ t('ALLOTMENT.NAME') }}: </label>
                                <input data-cy="allotment_creat_modal_name" type="text" id="name"
                                       v-model="allotment.name"
                                       name="name" @keyup="inputName(allotment.name)" class="form-control"
                                       placeholder="Digite o nome">
                                <div class="validation" v-if="isNameInvalid">{{ t(msgError) }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label for="url_site">{{ t('ALLOTMENT.URL_SITE_SOCIAL_MIDIA') }}: </label>
                                <input data-cy="allotment_creat_modal_email" type="text" id="url_site"
                                       v-model="allotment.url_site" name="url_site" @keyup="inputUrl"
                                       class="form-control" placeholder="URL">
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label for="initialPayment">{{ t('ALLOTMENT.TEMPLATE_PROPOSAL') }}: </label>
                                <select2 placeholder="template de proposta" :settings="{width: '100%'}"
                                         :options="templatesProposal" v-model="allotment.template_id"/>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label for="maxReserve" class="required">{{
                                        t('ALLOTMENT.MAX_NUMBER_RESERVE_PER_REALTOR')
                                    }}: </label>
                                <input data-cy="allotment_creat_modal_maxRes" type="number" id="maxReserve"
                                       v-model="allotment.max_number_reserve_per_realtor" name="maxReserve"
                                       @keyup="inputGeneric(allotment.max_number_reserve_per_realtor, 'maxReserve')"
                                       class="form-control" placeholder="00">
                                <div class="validation" id="invalidmaxReserve" style="display: none">
                                    {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label for="reserveDuration" class="required">{{
                                        t('ALLOTMENT.MAX_DURATION_RESERVES')
                                    }}: </label>
                                <input class="form-control" type="text" id="reserveDuration" name="reserveDuration"
                                       v-model.lazy="allotment.reserve_duration_max"
                                       v-money3="configDuration" @keyup="inputReserve">
                                <div class="validation" v-if="isReserveDurationInvalid">{{ msgReserveError }}</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" v-if="hasPermissionShowBd()">
                            <div class="form-group">
                                <label for="idBd">{{
                                        t('ALLOTMENT.ID_BD')
                                    }}: </label>
                                <input class="form-control" type="number" id="idBd" name="idBd"
                                       v-model="allotment.id_configuracao_bd" @wheel.prevent.stop="">
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label>{{ t('ALLOTMENT.CHARACTERISTICS_ALLOTMENT') }}: </label>
                                <editor :init="configTinyMce" v-model="allotment.description"
                                        tinymce-script-src="https://tiny.r2app.com.br/js/tinymce/tinymce.min.js"
                                >
                                </editor>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters mb-1">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="is_required_data_client"
                                       v-model="allotment.is_required_data_client">
                                <label class="custom-control-label" for="is_required_data_client">{{
                                        t('COMPANIES.MSG.REQUIRED_DATA_CLIENT_TO_CREATE_RESERVE')
                                    }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters mb-1">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="is_required_email"
                                       v-model="allotment.is_required_email">
                                <label class="custom-control-label" for="is_required_email">{{
                                        t('ALLOTMENT.REQUIRED_DATA_EMAIL_TO_CREATE_RESERVE')
                                    }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters mb-1">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input"
                                       id="permissions_allotment_on_marketing"
                                       v-model="allotment.permissions_allotment_on_marketing">
                                <label class="custom-control-label" for="permissions_allotment_on_marketing">{{
                                        t('ALLOTMENT.MSG.ALLOW_MARKETING_OF_ALLOTMENT')
                                    }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters mb-1">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input"
                                       id="permissions_informations_lots_on_marketing"
                                       v-model="allotment.permissions_informations_lots_on_marketing">
                                <label class="custom-control-label" for="permissions_informations_lots_on_marketing">{{
                                        t('ALLOTMENT.MSG.SHOW_VALUES_OF_LOTS_IN_PUBLIC_AND_MAP')
                                    }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters mb-1">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input"
                                       id="permissions_show_allotment_maps_on_marketing"
                                       v-model="allotment.permissions_show_allotment_maps_on_marketing">
                                <label class="custom-control-label" for="permissions_show_allotment_maps_on_marketing">
                                    {{ t('ALLOTMENT.MSG.DISPLAY_MAP_ALLOTMENT_IN_MARKETING') }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input"
                                       id="permissions_financing_simulator_on_marketing"
                                       v-model="allotment.permissions_financing_simulator_on_marketing">
                                <label class="custom-control-label" for="permissions_financing_simulator_on_marketing">{{
                                        t('ALLOTMENT.MSG.DISPLAY_FINANCING_SIMULATOR_FOR_MARKETING')
                                    }}</label>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="action_reserve_lot_on_map"
                                   v-model="allotment.action_reserve_lot_on_map">
                            <label class="custom-control-label"
                                   for="action_reserve_lot_on_map">{{
                                    t('ALLOTMENT.MSG.ACTION_RESERVE_LOT_ON_MAP')
                                }}</label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="action_calculate_financing_on_map"
                                   v-model="allotment.action_calculate_financing_on_map">
                            <label class="custom-control-label" for="action_calculate_financing_on_map">{{
                                    t('ALLOTMENT.MSG.ACTION_CALCULATE_FINANCING_ON_MAP')
                                }}</label>
                        </div>
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="action_view_lot_data_on_map"
                                   v-model="allotment.action_view_lot_data_on_map">
                            <label class="custom-control-label" for="action_view_lot_data_on_map">{{
                                    t('ALLOTMENT.MSG.ACTION_VIEW_LOT_DATA_ON_MAP')
                                }}</label>
                        </div>
                    </div>

                </div>
                <div class="tab-pane fade" :class="{ active: activeTab == 1, show: activeTab == 1 }" id="localization"
                     role="tabpanel" aria-labelledby="localization-tab">

                    <div class="row gutters">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label for="state" class="required">{{ t('ALLOTMENT.STATE') }}: </label>
                                <select2 :settings="{ width: '100%',placeholder: 'Selecione um Estado' }"
                                         id="state" name="state" v-model="state_id" :options="states"
                                         @select="indexCity(state_id), isStateInvalid = false"/>
                                <div class="validation" v-if="isStateInvalid">{{
                                        t('GENERAL.MSG.REQUIRED_FIELD')
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label for="city" class="required">{{ t('ALLOTMENT.CITY') }}: </label>
                                <select2 :settings="{ width: '100%', placeholder: 'Selecione uma Cidade' }" id="city"
                                         name="city" v-model="allotment.city_id" :options="cities"
                                         @select="onSelectCity($event)" v-if="state_id && !isLoadingCities"/>
                                <select2 :settings="{ width: '100%', placeholder: 'Carregando', disabled: true }"
                                         v-if="isLoadingCities"/>
                                <select2 :settings="{ width: '100%', disabled: true }" v-if="!state_id"/>
                                <div class="validation" v-if="isCityInvalid">{{ t('GENERAL.MSG.REQUIRED_FIELD') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label for="latitude">{{ t('ALLOTMENT.LATITUDE') }}: </label>
                                <input type="text" id="latitude" v-model="allotment.latitude" name="latitude"
                                       class="form-control">
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label for="longitude">{{ t('ALLOTMENT.LONGITUDE') }}: </label>
                                <input type="text" id="longitude" v-model="allotment.longitude" name="longitude"
                                       class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab == 2, show: activeTab == 2 }" id="financial"
                     role="tabpanel" aria-labelledby="financial-tab">
                    <div class="row gutters">
                        <div v-show="!allotment.use_new_calc" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <label for="financing_interest">{{ t('ALLOTMENT.FEES') }}: </label>
                            <div class="input-group">
                                <input type="text" v-money3="configFees" placeholder="00.00000"
                                       id="financing_interest" v-model.lazy="allotment.financing_interest"
                                       name="financing_interest" class="form-control">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="monetaryCorrection">{{ t('ALLOTMENT.READJUSTMENTS') }}: </label>
                                <select2 :settings="{ width: '100%', placeholder: t('GENERAL.NONE') }"
                                         id="monetaryCorrection"
                                         name="monetaryCorrection" v-model="allotment.monetary_correction_indexes_id"
                                         :options="monetaryCorrections"/>
                            </div>
                        </div>
                        <div v-show="!allotment.use_new_calc" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="initialPayment">{{ t('ALLOTMENT.INITIAL_PAYMENT') }}: </label>
                                <div class="input-group">
                                    <input class="form-control" type="text" id="initialPayment" name="initialPayment"
                                           v-model.lazy="allotment.initial_payment_required" v-money3="config">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{ t('GENERAL.TYPE') }}
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item bg-hover-white"
                                               @click="resetConfigMoney(false)">{{ t('GENERAL.REAIS') }}</a>
                                            <a class="dropdown-item bg-hover-white"
                                               @click="resetConfigMoney(true)">{{ t('GENERAL.PERCENTAGE') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="!allotment.use_new_calc" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="brokerage">{{ t('ALLOTMENT.BROKERAGE_VALUE') }}: </label>
                                <div class="input-group">
                                    <input class="form-control" type="text" id="brokerage" name="brokerage"
                                           v-model.lazy="allotment.brokerage_value" v-money3="configBrokerage">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{ t('GENERAL.TYPE') }}
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item bg-hover-white"
                                               @click="resetConfigBrokerage(false)">{{ t('GENERAL.REAIS') }}</a>
                                            <a class="dropdown-item bg-hover-white"
                                               @click="resetConfigBrokerage(true)">{{ t('GENERAL.PERCENTAGE') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                            <div class="form-group">
                                <label for="readjustPricePerMeter">{{
                                        t('ALLOTMENT.READJUST_PRICE_PER_METER')
                                    }}: </label>
                                <input class="form-control" type="text" id="readjustPricePerMeter"
                                       name="readjustPricePerMeter"
                                       v-model.lazy="allotment.readjust_price_per_meter" v-money3="configPricePerMeter"
                                       title="Campo para setar valor para reajustar o Lot quando o contrato é rescindido no Loteamento,
                                       valores superiores a zero serão aplicados.">
                            </div>
                        </div>
                        <div v-if="allotment.use_new_calc" class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                            <div class="form-group">
                                <label for="simulator_config_id">Configuração de Simulador: </label>
                                <select2 :settings="{ width: '100%' }" id="simulator_config_id"
                                         name="simulator_config_id" v-model="allotment.simulator_config_id"
                                         :options="simulatorsConfigs"/>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="use_new_calc"
                                       v-model="allotment.use_new_calc" name="use_new_calc">
                                <label class="custom-control-label pointer" for="use_new_calc"
                                       title="Uma nova configuração pode ser criada através do menu Configurações->Calc. de Financiamento">{{
                                        t('ALLOTMENT.USE_NEW_CALC')
                                    }}</label>
                            </div>
                        </div>
                        <div v-show="!allotment.use_new_calc" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="form-group">
                                <label for="formula">{{ t('ALLOTMENT.FORMULA') }}: </label>
                                <textarea class="form-control" id="formula" name="formula"
                                          v-model="allotment.financing_calculation_formula" placeholder="Formula"
                                          :style="{width: '100%', height: '100px'}"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer" style="justify-content: space-between">
                <div>
                    <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
                </div>
                <div>
                    <button data-cy="allotment_create_previous" type="button" v-if="activeTab != 0"
                            class="btn btn-secondary mr-2" @click="activeTab--">{{ t('ALLOTMENT.PREVIOUS') }}
                    </button>
                    <button data-cy="allotment_create_next" type="button" v-if="activeTab != 2"
                            class="btn btn-secondary mr-2" @click="next">{{ t('ALLOTMENT.NEXT') }}
                    </button>
                    <button data-cy="allotment_create_save" type="submit" v-if="activeTab == 2 || isEdit"
                            class="btn btn-primary">{{ t('ACTIONS.SAVE') }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import jwt_decode from 'jwt-decode';
import City from '@/services/City.js';
import State from '@/services/State.js';
import {Money3Directive} from 'v-money3';
import Editor from '@tinymce/tinymce-vue';
import {useToast} from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import Allotments from '@/services/Allotments';
import Proposal from '../../services/Proposal';
import Validate from '@/components/validate.vue';
import SimulatorConfig from '../../services/SimulatorConfig';
import MonetaryCorrection from '@/services/MonetaryCorrection.js';

export default {
    name: 'createAllotment',

    mixins: [Validate],

    directives: {money3: Money3Directive},

    components: {
        Editor,
        Select2,
    },

    props: {
        allotmentIndex: {
            type: Object,
            required: true,
        },
        isEdit: {
            type: Boolean,
            defaut: false,
        },
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },

    computed: {
        ...mapState({
            imageCropped: 'imageCropped',
        }),
    },

    data() {
        return {
            configTinyMce: {
                license_key: 'gpl',
                plugins: 'print preview paste searchreplace autolink autosave save directionality visualchars fullscreen link charmap hr pagebreak toc advlist lists wordcount textpattern  quickbars emoticons',
                menubar: 'file edit format',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 300,
                content_css: 'default',
                language: 'pt_BR',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            },
            cities: [],
            states: [],
            invalid: [],
            activeTab: 0,
            state_id: null,
            templatesProposal: {},
            allotment: {
                is_required_data_client: false,
            },
            msgError: null,
            msgReserveError: '',
            invalidSecondAba: [],
            isCityInvalid: false,
            isNameInvalid: false,
            isStateInvalid: false,
            reserveDuration: null,
            simulatorsConfigs: [],
            isLoadingCities: false,
            monetaryCorrections: [],
            monetaryCorrection: null,
            isReserveDurationInvalid: false,

            config: {
                min: 0,
                suffix: '%',
                masked: true,
                decimal: '.',
                precision: 2,
                disabled: false,
                thousands: '',
                allowBlank: false,
                disableNegative: true,
            },

            configPricePerMeter: {
                min: 0,
                suffix: '',
                masked: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                thousands: '.',
                disabled: false,
                allowBlank: false,
                max: 100000000000,
                disableNegative: true,
                minimumNumberOfCharacters: 1,
            },

            configBrokerage: {
                min: 0,
                suffix: '%',
                masked: true,
                decimal: '.',
                precision: 2,
                disabled: false,
                thousands: '',
                allowBlank: false,
                disableNegative: true,
            },

            configFees: {
                min: 0,
                max: 100,
                masked: true,
                decimal: '.',
                precision: 4,
                disabled: false,
                allowBlank: false,
                disableNegative: true,
                minimumNumberOfCharacters: 0,
            },

            configDuration: {
                min: 0,
                max: 999,
                masked: true,
                decimal: ':',
                precision: 2,
                disabled: false,
                allowBlank: false,
                disableNegative: true,
                minimumNumberOfCharacters: 0,
            },

            configEditor: {
                height: '200',
            },
        };
    },

    mounted() {
        this.$store.commit('changeLoading', true);
        this.$store.commit('setImageCropped', null);

        this.startModalInfs();
        this.indexMonetaryCorrection();
        this.indexState();
        this.listTemplateProposals();
        if (this.allotment) {
            this.setConfigBrokerage(this.allotment.brokerage_is_percentage);
        }
    },

    watch: {
        allotmentIndex: function () {
            this.$store.commit('setImageCropped', null);
            this.updateModalInfs();
            this.setConfigBrokerage(this.allotmentIndex.brokerage_is_percentage);
        },
    },

    methods: {
        hasPermissionShowBd() {
            const token = jwt_decode(localStorage.getItem('access_token'));
            return token.email.includes('@r2soft.com.br');
        },

        listTemplateProposals() {
            Proposal.listTemplate().then(resp => this.templatesProposal = resp.data);
        },
        formatterValue(value) {
            value = value.replace('R$ ', '');
            value = value.replaceAll('.', '');
            value = value.replaceAll(',', '.');
            value = parseFloat(value).toFixed(2);
            return value;
        },
        create() {
            this.startDependeciesAllotment();
            if (this.validateFirstAba() && this.validateSecondAba()) {
                this.allotment.image = this.imageCropped ? this.imageCropped : this.allotment.image;
                this.allotment.monetary_correction_indexes_id = this.allotment.monetary_correction_indexes_id == 0 ? null : this.allotment.monetary_correction_indexes_id;
                this.allotment.readjust_price_per_meter = this.formatterValue(this.allotment.readjust_price_per_meter);
                this.allotment.id ? this.upsert(Allotments.update(this.allotment), 'UPDATE_SUCCESS')
                    : this.upsert(Allotments.create(this.allotment), 'REGISTER_SUCCESS');
            }
        },

        upsert(promise, word) {
            this.$store.commit('changeLoading', true);

            promise.then(() => {
                document.getElementById('closeX').click();
                this.$store.commit('setImageCropped', null);
                this.toast.success(this.t(`GENERAL.MSG.${word}`));
                this.$emit('created');
            }).catch(err => this.errorMsg(err)).finally(() => this.$store.commit('changeLoading', false));
        },

        emailChange(description) {
            this.allotment.description = description;
        },

        startModalInfs() {
            this.allotment = this.allotmentIndex;
            this.setConfigMoney(this.allotmentIndex.initial_payment_required_is_percentage);
            this.allotment.description = this.allotmentIndex.description ? this.allotmentIndex.description : '';
            this.allotment.is_required_data_client = this.allotmentIndex.is_required_data_client ? this.allotmentIndex.is_required_data_client : false;
            this.allotment.initial_payment_required = this.validateMoney3(this.allotment.initial_payment_required);
            this.allotment.brokerage_value = this.validateMoney3(this.allotment.brokerage_value);
            this.allotment.simulator_config_id = this.allotment.simulator_config_id ?? 1;
            this.allotment.city_id = this.allotmentIndex.cities && this.allotmentIndex.cities.id ? this.allotmentIndex.cities.id : null;
            this.state_id = this.allotmentIndex.cities && this.allotmentIndex.cities.states.id ? this.allotmentIndex.cities.states.id : null;

            if (this.allotmentIndex.cities) {
                this.indexCity(this.state_id);
            }
        },

        listSimulatorsConfigurations() {
            SimulatorConfig.list()
                .then(res => this.simulatorsConfigs = res.data)
                .catch(err => this.errorMsg(err));
        },

        updateModalInfs() {
            this.activeTab = 0;
            this.isNameInvalid = false;
            this.isCityInvalid = false;
            this.isStateInvalid = false;
            this.allotment = this.allotmentIndex;
            this.isReserveDurationInvalid = false;
            document.getElementById('name').classList.remove('is-valid');
            document.getElementById('name').classList.remove('is-invalid');
            document.getElementById('reserveDuration').classList.remove('is-valid');
            document.getElementById('reserveDuration').classList.remove('is-invalid');
            this.setConfigMoney(this.allotmentIndex.initial_payment_required_is_percentage);
            this.allotment.description = this.allotmentIndex.description ? this.allotmentIndex.description : '';
            this.allotment.initial_payment_required = this.validateMoney3(this.allotment.initial_payment_required);
            this.allotment.brokerage_value = this.validateMoney3(this.allotment.brokerage_value);
            this.state_id = this.allotmentIndex.cities && this.allotmentIndex.cities.states.id ? this.allotmentIndex.cities.states.id : null;
            this.allotment.city_id = this.allotmentIndex.cities && this.allotmentIndex.cities.id ? this.allotmentIndex.cities.id : null;

            if (this.allotmentIndex.cities) {
                this.indexCity(this.state_id);
            }
        },

        startDependeciesAllotment() {
            var initialPaymentFinal;
            if (this.allotment.initial_payment_required_is_percentage) {
                initialPaymentFinal = this.allotment.initial_payment_required.toString().replaceAll('%', '');
                initialPaymentFinal = initialPaymentFinal.replaceAll(',', '.');
            } else {
                initialPaymentFinal = this.allotment.initial_payment_required.replaceAll('R$ ', '');
                initialPaymentFinal = initialPaymentFinal.replaceAll('.', '');
                initialPaymentFinal = initialPaymentFinal.replaceAll(',', '.');
            }
            var brokerageValueFinal;
            if (this.allotment.brokerage_is_percentage) {
                brokerageValueFinal = this.allotment.brokerage_value.replaceAll('%', '');
                brokerageValueFinal = brokerageValueFinal.replaceAll(',', '.');
            } else {
                brokerageValueFinal = this.allotment.brokerage_value.replaceAll('R$ ', '');
                brokerageValueFinal = brokerageValueFinal.replaceAll('.', '');
                brokerageValueFinal = brokerageValueFinal.replaceAll(',', '.');
            }
            this.allotment.cities = null;
            this.allotment.monetary_correction_indexes = null;
            this.allotment.initial_payment_required = initialPaymentFinal;
            this.allotment.brokerage_value = brokerageValueFinal;
        },

        next() {
            if (this.activeTab == 0) {
                if (this.validateFirstAba()) {
                    this.activeTab++;
                }
            } else if (this.activeTab == 1) {
                if (this.validateSecondAba()) {
                    this.activeTab++;
                }
            }
        },

        validateSecondAbaClick() {
            if (this.validateFirstAba()) {
                this.activeTab = 1;
            }
        },

        validateLastAbaClick() {
            if (this.validateSecondAba() && this.validateFirstAba()) {
                this.activeTab = 2;
            } else if (this.validateFirstAba()) {
                this.activeTab = 1;
            }
        },

        validateFirstAba() {
            this.invalid = [];

            this.reserveDurationValidate();
            this.nameValidate(this.allotment.name);
            this.validateGeneric(this.allotment.max_number_reserve_per_realtor, 'maxReserve');
            if (this.invalid.length && this.invalid.length > 0) {
                return false;
            }
            return true;
        },

        validateSecondAba() {
            this.invalidSecondAba = [];
            this.cityValidate();

            if (this.invalidSecondAba.length && this.invalidSecondAba.length > 0) {
                return false;
            }
            return true;
        },

        cityValidate() {
            if (!this.state_id) {
                this.isStateInvalid = true;
            } else
                this.isStateInvalid = false;


            if (!this.allotment.city_id) {
                this.isCityInvalid = true;
                this.invalidSecondAba.push(this.isCityInvalid);

            } else {
                this.isCityInvalid = false;
            }
        },

        reserveDurationValidate() {

            if (!this.allotment.reserve_duration_max || this.allotment.reserve_duration_max == '0:00') {
                this.isReserveDurationInvalid = true;
                this.msgReserveError = this.t('GENERAL.MSG.REQUIRED_FIELD');
                this.invalid.push(this.isReserveDurationInvalid);
                document.getElementById('reserveDuration').classList.add('is-invalid');

            } else {
                var reserve = this.allotment.reserve_duration_max.split(':');
                if (reserve[1] > 59) {
                    this.isReserveDurationInvalid = true;
                    this.msgReserveError = 'Quantidade de Minutos Invalida';
                    this.invalid.push(this.isReserveDurationInvalid);
                    document.getElementById('reserveDuration').classList.add('is-invalid');

                } else {
                    this.isReserveDurationInvalid = false;
                    document.getElementById('reserveDuration').classList.add('is-valid');
                }
            }
        },

        inputReserve() {
            this.isReserveDurationInvalid = false;
            document.getElementById('reserveDuration').classList.remove('is-valid');
            document.getElementById('reserveDuration').classList.remove('is-invalid');
            this.reserveDurationValidate();
        },

        resetConfigMoney(type) {
            this.allotment.initial_payment_required = 0;
            this.setConfigMoney(type);
        },

        resetConfigBrokerage(type) {
            this.allotment.brokerage_value = 0;
            this.setConfigBrokerage(type);
        },

        setConfigMoney(type) {
            this.allotment.initial_payment_required_is_percentage = type;
            if (type) {
                this.config.max = 100;
                this.config.prefix = '';
                this.config.decimal = '.';
                this.config.thousands = '';
                this.config.suffix = '%';
                this.config.disabled = false;
                this.config.minimumNumberOfCharacters = 0;
            } else {
                this.config.suffix = '';
                this.config.decimal = ',',
                    this.config.thousands = '.',
                    this.config.prefix = 'R$ ';
                this.config.disabled = false;
                this.config.max = 100000000000;
                this.config.minimumNumberOfCharacters = 1;
            }
        },

        setConfigBrokerage(type) {
            if (type === undefined) {
                type = true;
            }
            this.allotment.brokerage_is_percentage = type;
            if (type) {
                this.configBrokerage.max = 100;
                this.configBrokerage.prefix = '';
                this.configBrokerage.decimal = '.';
                this.configBrokerage.thousands = '';
                this.configBrokerage.suffix = '%';
                this.configBrokerage.disabled = false;
                this.configBrokerage.minimumNumberOfCharacters = 0;
            } else {
                this.configBrokerage.suffix = '';
                this.configBrokerage.decimal = ',';
                this.configBrokerage.thousands = '.';
                this.configBrokerage.prefix = 'R$ ';
                this.configBrokerage.disabled = false;
                this.configBrokerage.max = 100000000000;
                this.configBrokerage.minimumNumberOfCharacters = 1;
            }
        },

        async indexState() {
            await State.list().then(resp => {
                this.states = resp.data;
            });
            if (this.allotment.state_id) {
                this.state_id = this.allotment.state_id;
                this.indexCity(this.state_id);
            }
            this.$store.commit('changeLoading', false);
        },

        indexMonetaryCorrection() {
            MonetaryCorrection.list().then(resp => {
                this.monetaryCorrections = resp.data;
                this.monetaryCorrections.unshift({id: 0, text: this.t('GENERAL.NONE')});
            });
        },

        async indexCity(state_id) {
            this.isLoadingCities = true;
            await City.indexWithState(state_id).then(resp => {
                this.cities = resp.data;
                this.isLoadingCities = false;
            });
        },

        onSelectCity({id}) {
            this.allotment.city_id = id;
            document.getElementById('city').classList.remove('is-valid');
            document.getElementById('city').classList.remove('is-invalid');
            this.cityValidate();
        },

        uploadImage() {
            const file = document.querySelector('input[type=file]').files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                this.isCropping = true;
                this.$emit('logo', reader.result);
            };

            reader.readAsDataURL(file);
        },
    },
};
</script>
<style lang="css" scoped>
.right-content {
    justify-content: flex-end;
}

.wizard > .content > .body {
    position: relative !important;
}

.custom-control.custom-switch .custom-control-label::after {
    top: 4px !important;
}

.user-logo {
    max-width: 110px !important;
    max-height: 110px !important;
    margin-bottom: 1.2rem !important;
}

.logo-size {
    width: 110px !important;
    height: 110px !important;
}

.cropper-size {
    max-width: 350px !important;
    max-height: 275px !important;
}

.logo .status {
    top: 82px;
    right: 2px;
    width: 30px;
    height: 30px;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    border: 4px solid #f0f0f0;
}

.logo {
    position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;

}

input[type=number] {
    -moz-appearance: textfield;
}

.camera2 {
    right: 1;
    bottom: 0;
    outline: 0;
    cursor: pointer;
    font-size: 23px;
    position: absolute;
    border-radius: 15px;
    background-color: #f0f0f0;
}
</style>
