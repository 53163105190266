<template>
   <div class="modal-row2 modal-height-max">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 w-100 row mt-3 mb-1" style="position: sticky; top: 0">
         <div class="col-8">
            <input type="text" name="company" v-model="userFind" id="company"
                   class="form-control ml-4" @keyup="findUser"
                   placeholder="Pesquisar usuário">
         </div>
         <div class="col-4">
            <Select2 :settings="{ width: '100%', placeholder: 'Filtrar por'}" id="search"
                     name="search" v-model="filterStatus"
                     :options="optionsStatus" @select="filterBrokerStatus()"/>
         </div>
      </div>
      <div class="modal-body modal-row2" style="max-height: 52vh;">
         <div class="row">
            <div v-for="(user, key) in allUsers" :key="key"
                 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 w-100 border-top2">
               <div class="p-2 row d-flex  w-100 mr-1">
                  <div class="w-100 font-weight-bolder col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                     {{ user.text }}
                  </div>
                  <div v-if="user.active !== undefined"
                       class="w-100 font-weight-bolder col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                     <div class="custom-control custom-switch list-primary text-right">
                        <input type="checkbox" class="custom-control-input" :value="user.id"
                               :id="`customSwitch${key}`" v-model="user.active" @change="updateUser(user)">
                        <label class="custom-control-label "
                               :for="`customSwitch${key}`">{{ user.active ? "Ativo" : "Inativo" }}</label>
                     </div>
                  </div>
                  <div v-else class="w-100 font-weight-bolder col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                     <div class="custom-control custom-switch list-primary text-left">
                        <input type="checkbox" class="custom-control-input"
                               @click="setAddUserAllotmentModal(allotment, user, key)"
                               :value="user.id"
                               :id="`customSwitch${key}`" v-model="user.active">
                        <label class="custom-control-label " :for="`customSwitch${key}`">Conceder acesso</label>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="modal-footer justify-content-end">
         <div>
            <button class="btn btn-primary" data-dismiss="modal">{{ t('ACTIONS.CLOSE') }}
            </button>
         </div>
      </div>
      <transition name="fade">
         <div class="modal-mask" v-if="add">
            <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
               <div class="modal-dialog" role="document" style="margin-top: 0rem;">
                  <div class="modal-content">
                     <div class="modal-open">
                        <div class="modal-body modal-row2" style="max-height: calc(100vh - 200px);">
                           <div class="row gutters">
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex  mb-4">
                                 <h5 class="modal-title title-size d-flex ">
                                    {{ t('ALLOTMENT.REALLY_ACCESS_THIS_USER') }} ?</h5>
                              </div>
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                 <div class="form-group d-flex ">
                                    <label>{{ t('LOTS.ALLOTMENT') }}:</label>
                                    <label class="ml-1 color-grey">{{ allotment.name }}</label>
                                 </div>
                              </div>
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                 <div class="form-group d-flex ">
                                    <label>{{ t('RESERVE.REALTOR') }}: </label>
                                    <label class="ml-1 color-grey">{{ user.text }}</label>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer justify-content-center">
                        <button class="btn btn-secondary mr-4" type="button" @click="close()">{{
                              t("ACTIONS.CLOSE")
                           }}
                        </button>
                        <button data-cy="user_index_add_phone_save" class="btn btn-primary" type="button"
                                @click="createUserAllotment()">{{ t("ACTIONS.SAVE") }}
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </transition>
   </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import User from '@/services/user';
import UserAllotment from "../../services/UserAllotment";
import Select2 from 'vue3-select2-component';

export default {
   name: "showBrokersComponent",
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   components: {
      Select2
   },
   data() {
      return {
         allUsers: null,
         allotment: null,
         add: false,
         user: null,
         userAux: null,
         users: null,
         userFind: null,
         usersBackup: null,
         filterStatus: null,
         optionsStatus: [
            {
               id: 0,
               text: 'Ativo'
            },
            {
               id: 1,
               text: 'Inativo'
            },
            {
               id: 3,
               text: 'Sem acesso'
            },
            {
               id: 4,
               text: 'Limpar'
            }
         ]
      }
   },
   props: {
      allotmentIndex: {
         type: Object,
         required: true,
      }
   },
   mounted() {
      this.$store.commit('changeLoading', true);
      this.allotment = this.allotmentIndex;
      this.listAllBrokes();
   },
   watch: {
      allotmentIndex: function () {
         this.allotment = this.allotmentIndex;
         this.allUsers = null;
         this.add = false;
         this.user = null;
         this.userAux = null;
         this.users = null;
         this.userFind = null;
         this.usersBackup = null;
         this.filterStatus = null;
         this.listAllBrokes();
      }
   },
   methods: {
      findUser() {
         this.userAux = [];
         this.filterStatus = null;
         this.users = this.usersBackup;
         this.users.forEach(user => {
            let nameUser = user.text.toUpperCase();
            let userFind = this.userFind.toUpperCase();
            if (nameUser.includes(userFind)) {
               this.userAux.push(user);
            }
         })
         this.allUsers = this.userAux;
      },
      filterBrokerStatus() {
         this.userAux = [];
         this.users = this.usersBackup;
         this.userFind = null;
         if (this.filterStatus === '3') {
            this.users.forEach(user => {
               let hasKey = false;
               for (const userKey in user) {
                  if (userKey === 'active') hasKey = true;
               }
               if (!hasKey) this.userAux.push(user);
            })
         } else if (this.filterStatus === '1') {
            this.users.forEach(user => {
               for (const userKey in user) {
                  if (userKey === 'active' && !user.active && user.active !== undefined) this.userAux.push(user);
               }
            })
         } else if (this.filterStatus === '0') {
            this.users.forEach(user => {
               for (const userKey in user) {
                  if (userKey === 'active' && user.active) this.userAux.push(user);
               }
            })
         } else {
            this.filterStatus = null;
            this.userAux = this.usersBackup;
         }
         this.allUsers = this.userAux;
      },

      listAllBrokes() {
         this.$store.commit('changeLoading', true);
         UserAllotment.listBrokesByAllotment(this.allotment.id).then(async (resp) => {
            resp.data?.validations ? this.allUsers = [] : this.allUsers = resp.data;
            await User.listAll().then((resp) => {
               let arr = [];
               resp.data.forEach((item) => {
                  let inArray = false;
                  this.allUsers?.forEach(user => {
                     if (item.id === user.user_id) {
                        inArray = true;
                     }
                  })
                  if (!inArray) arr.push(item);
               });
               this.allUsers?.push(...arr);
               this.$store.commit('changeLoading', false);
            });
            this.allUsers.sort(function (a, b) {
               return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
            });
            this.usersBackup = this.allUsers;
         })
      },

      setAddUserAllotmentModal(userAllotment, user) {
         this.add = false;
         this.user = user;
         this.name = user.text;
         this.add = true;
      },

      createUserAllotment() {
         this.$store.commit('changeLoading', true);
         const userAllotment = {
            allotment_id: this.allotment.id,
            user_id: this.user.id,
            max_number_reserves: this.allotment.max_number_reserve_per_realtor,
            is_realtor: true,
            active: true
         }
         UserAllotment.create(userAllotment, this.allotment.id).then((resp) => {
            this.add = false;
            this.allUsers?.forEach((item, key) => {
               if (item.id === resp.data.user_id) {
                  this.allUsers[key] = resp.data;
                  this.allUsers[key].text = this.user.text;
                  this.usersBackup.forEach((backup, key2) => {
                     if (backup.id === resp.data.user_id) {
                        this.usersBackup[key2] = this.allUsers[key];
                     }
                  })
               }
            });
            this.filterBrokerStatus();
            this.toast.success(this.t('ALLOTMENT.USER_CREATE_IN_ALLOTMENT'));
            this.$store.commit('changeLoading', false);
         }).catch(() => {
                this.allUsers?.forEach((item, key) => {
                   if (item.id === this.user.id) {
                      this.allUsers[key].active = undefined;
                   }
                })
                this.add = false;
                this.$store.commit('changeLoading', false)
             }
         );
      },
      close() {
         this.allUsers?.forEach((item, key) => {
            if (item.id === this.user.id) {
               this.allUsers[key].active = undefined;
            }
         })
         this.add = false;
      },
      updateUser(user) {
         this.$store.commit('changeLoading', true);
         UserAllotment.updateIsRealtor(user).then((resp) => {
            this.usersBackup.forEach(item => {
               if (item.id === resp.data.user_id)
                  item.active = resp.data.active;
            })
            this.filterBrokerStatus();
            this.$store.commit('changeLoading', false);
            if (resp.data.active) {
               this.toast.success(this.t('ALLOTMENT.USER_ALLOTMENT_ACTIVED'));
            } else {
               this.toast.success(this.t('ALLOTMENT.USER_ALLOTMENT_DESACTIVED'));
            }
         }).catch((err) => {
            this.allUsers?.forEach((item, key) => {
               if (item.id === user.id) {
                  this.allUsers[key].active = !user.active;
               }
            })
            this.toast.error(err.response.data.message);
            this.$store.commit('changeLoading', false);
         });
      }
   }
}
</script>

<style scoped>

</style>