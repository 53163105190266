import {http, httpAuth} from './config';

export default {
    index:() => {
        return http.get('api/v1/estados');
    },
    list:() => {
        return http.get('api/v1/estados/listar');
    },
    listOauth:() => {
        return httpAuth.get('v1/estados/listar');
    }
}